import { Claim } from '@src/interfaces/RootState';
import { RESET, SET_ACTIVE_CLAIM, SET_CLAIM_HISTORY, SET_CLAIM_NUMBER, SET_CLAIM_TYPE_LIST } from './actions';

const initialState: Claim = {
    claimHistory: null,
    claimTypeList: [],
    claimNumber: null,
    isActiveClaim: null, // ID of active claim: NUMBER
};

export default function claimReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_CLAIM:
            return {
                ...state,
                isActiveClaim: action.val,
            };
        case SET_CLAIM_HISTORY:
            return {
                ...state,
                claimHistory: action.val,
            };
        case SET_CLAIM_NUMBER:
            return {
                ...state,
                claimNumber: action.val,
            };
        case SET_CLAIM_TYPE_LIST:
            return {
                ...state,
                claimTypeList: action.val,
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
}

/* eslint-disable no-console */
import React, { ReactNode, useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

import { AuthContext } from './authContext';
import RootState from '@src/interfaces/RootState';
import { useTranslation } from 'react-i18next';
import { REFRESH_TOKEN } from '@src/redux/auth/actions';

interface PrivateRouteProps {
    children: ReactNode;
    exact?: boolean;
    props?: any;
    path?: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const {
        authState: { isAuthenticated },
    } = React.useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    const history = useHistory();

    /* FUNCTIONS */
    const logOut = () => {
        console.log('logOut');
        sessionStorage.clear();
        authDispatch({ type: 'SIGNED_OUT' });
        history.replace('/');
    };

    const refreshToken = () => {
        dispatchStore({ type: REFRESH_TOKEN });
    };

    const handleLogout = () => {
        console.log('handleLogout');
        if (isAuthenticated) {
            logOut();
        }
    };

    const handleOnIdle = () => {
        console.log('handleOnIdle');
        if (isAuthenticated) {
            refreshToken();
            reset();
        }
    };

    const { getLastActiveTime, reset } = useIdleTimer({
        timeout: 1000 * 180 * 1,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const { getRemainingTime } = useIdleTimer({
        timeout: 1000 * 1800 * 1,
        onIdle: handleLogout,
        debounce: 500,
    });

    /* USEEFFECTS */
    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Logged out');
            history.replace('/');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            refreshToken();
        }
    }, []);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;

// import BpostService, { QueryParams } from '@src/api/services/bpostService';
import BpostService, { QueryParams } from '@src/api/services/bpostService';
import RootState from '@src/interfaces/RootState';
import { change } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING } from '../ui/actions';
/** Saga Actions */
export const GET_LOCALITY = 'GET_LOCALITY';
export const GET_STREET = 'GET_STREET';
export const GET_HOUSE_NUMBER = 'GET_HOUSE_NUMBER';
export const GET_BOX_NUMBER = 'GET_BOX_NUMBER';
export const BPOST_CHECK = 'BPOST_CHECK';

/** Reducer Actions */
export const SET_LOCALITY = 'SET_LOCALITY';
export const SET_STREET = 'SET_STREET';
export const SET_HOUSE_NUMBER = 'SET_HOUSE_NUMBER';
export const SET_BOX_NUMBER = 'SET_BOX_NUMBER';
export const ALLOW_FIELDS_EDIT = 'ALLOW_FIELDS_EDIT';
export const RESET_BPOST = 'RESET_BPOST';

class BPost {
    /**
     * Generate payload for Bpost request
     */
    public static *generateRequestPayload({ val: { formName, requestType, targetValue } }) {
        let payload: Partial<QueryParams> | undefined;
        switch (requestType) {
            case 'locality':
                payload = {
                    requestType: requestType,
                    id: 15,
                    query: targetValue,
                    maxNumberOfSuggestions: 5,
                };
                break;
            case 'street':
                payload = {
                    requestType: requestType,
                    id: 1,
                    query: targetValue,
                    localityName: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).city]),
                    postalCode: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).postCode]),
                    maxNumberOfSuggestions: 5,
                };
                break;
            case 'streetnumber':
                payload = {
                    requestType: requestType,
                    id: 2,
                    query: targetValue,
                    localityName: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).city]),
                    postalCode: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).postCode]),
                    streetName: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).street]),
                    maxNumberOfSuggestions: 5,
                };
                break;
            case 'boxNumber':
                payload = {
                    requestType: requestType,
                    id: 2,
                    query: targetValue,
                    localityName: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).city]),
                    postalCode: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).postCode]),
                    streetName: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).street]),
                    houseNumber: yield select((state: RootState) => state?.form[formName]?.values?.[fieldList(formName).houseNumber]),
                    maxNumberOfSuggestions: 5,
                };
                break;
            default:
                return payload;
        }
        return payload;
    }
    /**
     * Insert options to store
     */
    public static *insertOptions(response, requestType) {
        let options;
        switch (requestType) {
            case 'locality':
                options = response?.data?.response?.topSuggestions.map((item, index) => ({
                    label: `${item.address.postalCode} ${item.address.municipalityName}`,
                    value: item.address.string,
                    city: item.address.municipalityName,
                    postCode: item.address.postalCode,
                    key: item?.address?.string + index,
                }));
                yield put({ type: SET_LOCALITY, val: options });
                break;
            case 'street':
                options = response?.data?.response?.topSuggestions.map((item, index) => ({
                    label: `${item.address?.streetName}`,
                    value: item.address?.streetName,
                    key: item?.address?.streetName + index,
                }));
                yield put({ type: SET_STREET, val: options });
                break;
            case 'streetnumber':
                options = response?.data?.response?.topSuggestions.map((item, index) => ({
                    label: `${item?.address?.houseNumber}`,
                    value: item.address?.houseNumber,
                    key: item?.address?.houseNumber + index,
                }));
                yield put({ type: SET_HOUSE_NUMBER, val: options });
                break;
            case 'boxNumber':
                options = response?.data?.response?.topSuggestions?.map((item, index) => ({
                    label: `${item.address.boxNumber}`,
                    value: item.address.boxNumber,
                    key: item?.address?.boxNumber + index,
                }));
                yield put({ type: SET_BOX_NUMBER, val: options });
                break;
            default:
                return options;
        }
    }
}
/** Function */
export function* bpostCheck(data) {
    if (data?.val?.targetValue === '') return;

    yield put({ type: SET_LOADING, val: true });
    /**
     * In case of "city" changes, reset the other address fields
     * street, housenumber, boxnumber
     */
    if (data?.val?.requestType === 'locality') {
        yield put({ type: SET_STREET, val: null });
        yield put({ type: SET_HOUSE_NUMBER, val: null });
        yield put({ type: SET_BOX_NUMBER, val: null });
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).street, ''));
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).postCode, ''));
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).houseNumber, ''));
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).boxNumber, ''));
    }
    if (data?.val?.requestType === 'street') {
        yield put({ type: SET_HOUSE_NUMBER, val: null });
        yield put({ type: SET_BOX_NUMBER, val: null });
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).houseNumber, ''));
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).boxNumber, ''));
    }
    if (data?.val?.requestType === 'streetnumber') {
        yield put({ type: SET_BOX_NUMBER, val: null });
        yield put(change(data?.val?.formName, fieldList(data?.val?.formName).boxNumber, ''));
    }

    // Create payload based on the "requestType"
    const payload = yield BPost.generateRequestPayload(data);

    try {
        const response = yield call(BpostService.bpostCheck, payload);
        if (response.status === 200 && response?.data?.response?.topSuggestions) {
            const newRes = processResponse(response, payload?.postalCode, data?.val?.requestType);

            yield BPost.insertOptions(response, data?.val?.requestType);
        } else if (!response?.data?.response?.topSuggestions) {
            return;
        } else {
            yield put({ type: ALLOW_FIELDS_EDIT, val: true });
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
        yield put({ type: ALLOW_FIELDS_EDIT, val: true });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

function fieldList(form) {
    return {
        city: `city${form === 'accountHolderForm' ? 'Ah' : ''}`,
        street: `street${form === 'accountHolderForm' ? 'Ah' : ''}`,
        postCode: `postCode${form === 'accountHolderForm' ? 'Ah' : ''}`,
        houseNumber: `houseNumber${form === 'accountHolderForm' ? 'Ah' : ''}`,
        boxNumber: `box${form === 'accountHolderForm' ? 'Ah' : ''}`,
    };
}

// Response processing for street to avoid different postcodes
function processResponse(response, postCode, requestType) {
    if (requestType === 'street') {
        const newRes = { ...response };
        newRes?.data?.response?.topSuggestions.filter((address) => address?.postalCode === postCode);
        return newRes;
    } else return response;
}

import React, { FC, useReducer } from 'react';
import cookie from 'react-cookies';
import { AuthContext } from './authContext';

const INITIAL_STATE = {
    // isAuthenticated: true,
    isAuthenticated: sessionStorage.getItem('token') ? true : false,
};

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SIGNING_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
            };
        case 'SIGNED_OUT':
            return {
                ...state,
                isAuthenticated: false,
            };
        default:
            return state;
    }
}

type Props = {
    children: React.ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
    const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
    return <AuthContext.Provider value={{ authState, authDispatch }}>{children}</AuthContext.Provider>;
};

import { takeLatest } from 'redux-saga/effects';
import {
    bpostCheck,
    BPOST_CHECK,
    // , getBoxNumber, getHouseNumber, getLocality, getStreet, GET_BOX_NUMBER, GET_HOUSE_NUMBER, GET_LOCALITY, GET_STREET
} from './actions';

export default [
    takeLatest(BPOST_CHECK, bpostCheck),
    // takeLatest(GET_LOCALITY, getLocality),
    // takeLatest(GET_STREET, getStreet),
    // takeLatest(GET_HOUSE_NUMBER, getHouseNumber),
    // takeLatest(GET_BOX_NUMBER, getBoxNumber),
];

import { ClaimTypeInterface } from '../interfaces/Claim';

class ClaimTypeModel implements ClaimTypeInterface {
    name;
    value;
    label;
    constructor(item) {
        this.name = item.name;
        this.value = item.code;
        this.label = item.name;
    }
}

export default ClaimTypeModel;
